var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-tabs',[_c('form-tab',{attrs:{"name":"tab1","tab-errors":_vm.tabErrors,"title":_vm.$t('labels.company_settings'),"to":{
            name: _vm.routes.edit,
            params: { companyId: _vm.companyId },
            query: { tab: 0 },
        },"exact":"","replace":_vm.inForm}}),(_vm.isSuperAdmin)?_c('form-tab',{attrs:{"name":"tab2","tab-errors":_vm.tabErrors,"title":_vm.$t('labels.company_details'),"to":{
            name: _vm.routes.edit,
            params: { companyId: _vm.companyId },
            query: { tab: 1 },
        },"exact":"","replace":_vm.inForm}}):_vm._e(),(_vm.companyId)?_c('form-tab',{attrs:{"name":"tab3","title":_vm.$t('labels.company_addresses'),"to":{
            name: _vm.routes.addressList,
            params: { companyId: _vm.companyId },
        }}}):_vm._e(),(_vm.companyId)?_c('form-tab',{attrs:{"name":"tab4","title":_vm.$t('labels.company_allowances'),"to":{
            name: _vm.routes.allowanceList,
            params: { companyId: _vm.companyId },
        }}}):_vm._e(),(_vm.isSuperAdmin)?_c('form-tab',{attrs:{"name":"tab5","tab-errors":_vm.tabErrors,"title":_vm.$t('labels.cc_partner_relations'),"to":{
            name: _vm.routes.edit,
            params: { companyId: _vm.companyId },
            query: { tab: 2 },
        },"exact":"","replace":_vm.inForm}}):_vm._e(),(_vm.companyId)?_c('form-tab',{attrs:{"name":"tab6","title":_vm.$t('labels.company_cloudcanteens'),"to":{
            name: _vm.routes.canteenList,
            params: { companyId: _vm.companyId },
        }}}):_vm._e(),(_vm.companyId)?_c('form-tab',{attrs:{"name":"tab7","title":_vm.$t('labels.employees'),"to":{
            name: _vm.routes.employeeList,
            params: { companyId: _vm.companyId },
        }}}):_vm._e(),(_vm.companyId)?_c('form-tab',{attrs:{"name":"tab8","title":_vm.$t('labels.teams'),"to":{
            name: _vm.routes.teamsList,
            params: { companyId: _vm.companyId },
        }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }