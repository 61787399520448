<template>
    <v-btn
        :to="route"
        color="primary"
        class="white--text"
        :depressed="depressed"
        @click="$emit('create')"
    >
        <v-icon left>add</v-icon>
        {{ label ? label : $t('actions.create') }}
    </v-btn>
</template>

<script>
export default {
    props: {
        routeName: {
            type: String,
            default: null,
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        depressed: {
            type: Boolean,
        },
        label: {
            type: String,
            default: '',
        },
        tabbed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        route() {
            const to = this.routeName ? { name: this.routeName } : this.to
            if (this.routeName && this.tabbed) {
                to.query = { tab: 0 }
            }

            return to
        },
    },
}
</script>
