<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="{ company: { exact: company } }"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                :row-select="editable"
                :row-action="editable"
                @onRowSelected="onRowSelected"
            >
                <!-- name column -->
                <template v-slot:[`item.name`]="{ item }">
                    <router-link v-if="editable" :to="getEditLink(item)">
                        {{ item.name }}
                    </router-link>
                    <template v-else>
                        {{ item.name }}
                    </template>
                </template>

                <!-- row actions -->
                <template v-if="editable" v-slot:rowActions="{ item }">
                    <edit-button :to="getEditLink(item)"></edit-button>
                    <delete-action
                        :entity-name="item.name"
                        :entity-id="item.id"
                        :api="api"
                        @deleted="onEntityDeleted"
                        @failed="onEntityDeleteFailed"
                    ></delete-action>
                </template>
            </d-table>
        </template>

        <!-- batch actions -->
        <template v-if="editable" v-slot:batchActions>
            <delete-batch-action
                :selected-ids="selectedIds"
                :api="api"
                @finished="onBatchDeleteFinished"
                @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                @canceled="onBatchDeleteCanceled"
            ></delete-batch-action>
        </template>
    </data-iterator>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import AddressesHeaders from '@/pages/users/mixins/AddressesHeaders'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, AddressesHeaders],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            api: RestApiType.COMPANY_ADDRESSES,
        }
    },
    computed: {
        restApi() {
            return RestApiCollection.get(this.api)
        },

        company() {
            return this.restApi.getItemIri(this.companyId)
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.COMPANY_ADDRESS_EDIT,
                params: { companyId: this.companyId, addressId: item.id },
            }
        },
    },
}
</script>
