<template>
    <form-tabs>
        <form-tab
            name="tab1"
            :tab-errors="tabErrors"
            :title="$t('labels.company_settings')"
            :to="{
                name: routes.edit,
                params: { companyId },
                query: { tab: 0 },
            }"
            exact
            :replace="inForm"
        ></form-tab>
        <form-tab
            v-if="isSuperAdmin"
            name="tab2"
            :tab-errors="tabErrors"
            :title="$t('labels.company_details')"
            :to="{
                name: routes.edit,
                params: { companyId },
                query: { tab: 1 },
            }"
            exact
            :replace="inForm"
        ></form-tab>
        <form-tab
            v-if="companyId"
            name="tab3"
            :title="$t('labels.company_addresses')"
            :to="{
                name: routes.addressList,
                params: { companyId },
            }"
        ></form-tab>
        <form-tab
            v-if="companyId"
            name="tab4"
            :title="$t('labels.company_allowances')"
            :to="{
                name: routes.allowanceList,
                params: { companyId },
            }"
        ></form-tab>
        <form-tab
            v-if="isSuperAdmin"
            name="tab5"
            :tab-errors="tabErrors"
            :title="$t('labels.cc_partner_relations')"
            :to="{
                name: routes.edit,
                params: { companyId },
                query: { tab: 2 },
            }"
            exact
            :replace="inForm"
        ></form-tab>
        <form-tab
            v-if="companyId"
            name="tab6"
            :title="$t('labels.company_cloudcanteens')"
            :to="{
                name: routes.canteenList,
                params: { companyId },
            }"
        ></form-tab>
        <form-tab
            v-if="companyId"
            name="tab7"
            :title="$t('labels.employees')"
            :to="{
                name: routes.employeeList,
                params: { companyId },
            }"
        ></form-tab>
        <form-tab
            v-if="companyId"
            name="tab8"
            :title="$t('labels.teams')"
            :to="{
                name: routes.teamsList,
                params: { companyId },
            }"
        ></form-tab>
    </form-tabs>
</template>

<script>
import routeType from '@/router/routeType'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormTab from '@/components/form/FormTab'
import FormTabs from '@/components/form/FormTabs'

export default {
    components: { FormTab, FormTabs },
    mixins: [AuthMixin],
    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
        tabErrors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            routeType,
        }
    },
    computed: {
        inForm() {
            return (
                this.$route.matched[2]?.name === routeType.COMPANY_EDIT ||
                this.$route.matched[1]?.name === routeType.COMPANY_CREATE
            )
        },
        routes() {
            let isSelf =
                this.$route.matched[0]?.name === routeType.COMPANY_SELF_EDIT

            return isSelf
                ? {
                      edit: routeType.COMPANY_SELF_EDIT,
                      addressList: routeType.COMPANY_SELF_ADDRESS_LIST,
                      allowanceList: routeType.COMPANY_SELF_ALLOWANCE_LIST,
                      canteenList: routeType.COMPANY_CLOUDCANTEEN_LIST,
                      employeeList: routeType.COMPANY_EMPLOYEES,
                      teamsList: routeType.COMPANY_TEAMS_LIST,
                  }
                : {
                      edit: this.companyId
                          ? routeType.COMPANY_EDIT
                          : routeType.COMPANY_CREATE,
                      addressList: routeType.COMPANY_ADDRESS_LIST,
                      allowanceList: routeType.COMPANY_ALLOWANCE_LIST,
                      canteenList: routeType.COMPANY_CLOUDCANTEEN_LIST,
                      employeeList: routeType.COMPANY_EMPLOYEES,
                      teamsList: routeType.COMPANY_TEAMS_LIST,
                  }
        },
    },
}
</script>
