<template>
    <div>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="companyId"
        ></company-route-tabs>
        <company-address-form
            :company-id="companyId"
            :entity-id="addressId"
        ></company-address-form>
    </div>
</template>

<script>
import CompanyAddressForm from '@/components/pages/companyAddresses/Form'
import CompanyRouteTabs from '@/components/pages/companies/RouteTabs'
import dirtyForm from '@/components/mixins/dirtyForm'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: { CompanyAddressForm, CompanyRouteTabs },

    mixins: [dirtyForm, AuthMixin],

    props: {
        addressId: {
            type: [String, Number],
            default: null,
        },
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
