<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template slot="col1">
                        <text-input
                            v-model="form.uniqueId"
                            v-validate="'max:255|space'"
                            name="uniqueId"
                            label="labels.unique_id"
                            :error-messages="errors.collect('uniqueId')"
                            :data-vv-as="$t('labels.unique_id')"
                        ></text-input>
                    </template>
                </form-two-col-row>
            </form-panel>
            <addresses-form-part
                v-model="form"
                name="addressFormPart"
            ></addresses-form-part>

            <form-panel
                v-if="isSuperAdmin"
                :title="$t('labels.oc_contact_details')"
            >
                <form-two-col-row>
                    <template slot="col1">
                        <text-input
                            v-model="form.ocContactFirstName"
                            v-validate="'max:255|space'"
                            name="ocContactFirstName"
                            label="labels.first_name"
                            counter="255"
                            :error-messages="
                                errors.collect('ocContactFirstName')
                            "
                            :data-vv-as="$t('labels.first_name')"
                        ></text-input>
                    </template>
                    <template slot="col2">
                        <text-input
                            v-model="form.ocContactLastName"
                            v-validate="'max:255|space'"
                            name="ocContactLastName"
                            label="labels.last_name"
                            counter="255"
                            :error-messages="
                                errors.collect('ocContactLastName')
                            "
                            :data-vv-as="$t('labels.last_name')"
                        ></text-input>
                    </template>
                </form-two-col-row>
                <form-two-col-row>
                    <template slot="col1">
                        <text-input
                            v-model="form.ocContactPhone"
                            v-validate="'max:255|phonenumber'"
                            name="ocContactPhone"
                            label="labels.phone"
                            counter="255"
                            :error-messages="errors.collect('ocContactPhone')"
                            :data-vv-as="$t('labels.phone')"
                            @blur="
                                form.ocContactPhone = formatPhoneNumber(
                                    form.ocContactPhone
                                )
                            "
                        ></text-input>
                    </template>
                </form-two-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import AddressesFormPart from '@/components/pages/addresses/AddressesFormPart'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        AddressesFormPart,
    },
    mixins: [FormBase, PhoneNumberMixin, AuthMixin],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: ApiClientType.COMPANY_ADDRESSES,
            mapper: MapperType.COMPANY_ADDRESSES_FORM,
            form: {
                uniqueId: '',
                name: null,
                invoiceName: '',
                phone: '',
                longitude: null,
                latitude: null,
                fullAddress: null,
                houseNumber: '',
                street: '',
                town: '',
                zipCode: '',
                sublocality: '',
                administrativeAreaLevel1: '',
                administrativeAreaLevel2: '',
                administrativeAreaLevel3: '',
                administrativeAreaLevel4: '',
                country: '',
                countryCode: '',
                subAddress: '',
                placeId: '',
                comment: '',
                ocContactFirstName: null,
                ocContactLastName: null,
                ocContactPhone: null,
            },
            entity: 'labels.company_addresses',
            editRoute: routeType.COMPANY_ADDRESS_EDIT,
            listRoute: routeType.COMPANY_ADDRESS_LIST,
        }
    },
    created() {
        this.form.company = RestApiCollection.get(
            ApiClientType.COMPANIES
        ).getItemIri(this.companyId)
    },
    methods: {
        editRedirectParams(data) {
            return {
                addressId: data.id,
                companyId: this.companyId,
            }
        },
        listRedirectParams() {
            return { companyId: this.companyId }
        },
    },
}
</script>
